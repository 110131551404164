<template>
  <div class="nav-menu">
    <p @click="goDiputaciones" class="cursor">Regresar</p>
  </div>
</template>

<script>
export default {
    methods:{
        goDiputaciones(){
            this.$router.push( { name:"municipios-ejercicio-cargo", parans: { municipio: this.$router.currentRoute._value.params.municipio } }) 
        }
    }
}
</script>

<style lang="scss">  

    .nav-menu{
        display:flex;
        p{
            width:120px;
            height:40px;
            display:flex; align-items: center; justify-content:center;
            font-size:12px;
            color: black;
            font-size:14px;
            font-weight: bold;
            margin:10px 10px 0 10px;
            text-decoration:underline;
        }
    }



</style>